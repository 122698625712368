angular.module("adminApp")
    .factory('chooseLicenseAdminModal', ["$uibModal", function ($uibModal) {
        function open(licenses) {
            var modal = $uibModal.open({
                size: "md",
                resolve: {
                    licenses: function () {
                        return licenses;
                    }
                },
                templateUrl: "/static/templates/project/chooseLicenseModal.html",
                controller: "ChooseLicenseAdminModalCtrl"
            });
            return modal.result;
        }

        return {
            open: open
        };
    }])
    .controller("ChooseLicenseAdminModalCtrl", ["$scope", "$uibModalInstance", "Notification", "licenses", function ($scope, $uibModalInstance, Notification, licenses) {

        $scope.licenseKeys = licenses;
        var now = new Date().getMilliseconds();
        $scope.notExpiredLicenseKeys = licenses.filter(function(license) {
            return !license.expirationDate || license.expirationDate > now;
        });
        $scope.chosenLicenseKey = "";

        $scope.chooseLicense = function () {
            $uibModalInstance.close($scope.chosenLicenseKey);
        }
    }]);